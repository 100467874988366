import React, { useState } from "react";
import "./lead.scss";
import { Container, Row, Col } from "react-bootstrap";
import Banner from "./img/logo.svg";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";

export default function Form() {
  
  const partnersArr = [];
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    businessType: "",
    businessName: "",
    businessAddress: "",
    website: "",
    url: "",
    trading: "",
    partners: [],
    service: "",
    update: "",
    receive: "",
  });

  const thirdparty = ["Deliveroo", "Uber Eats", "Just eat", "Others"];
  const [formErrors, setFormErrors] = useState({});

const handleChange = (event) => {
  const { name, value, type, checked } = event.target;
  let newFormData = { ...formData };
  let errors = { ...formErrors };
  if (type === "checkbox" && name === "partners") {
    let partnersArr = [...newFormData.partners];

    if (checked) {
      partnersArr.push(value);
    } else {
      partnersArr = partnersArr.filter((partner) => partner !== value);
    }

    // Update the formData with the new partners array
    newFormData = { ...newFormData, partners: partnersArr };
  } else {

      switch (name) {
      case "name":
        errors.name = value.length < 3 ? "Valid Name is required" : "";
        break;
      case "mobileNumber":
        errors.mobileNumber =
          (value.length < 10 || value.length > 13 || !/^\d+$/.test(value))
            ? "Mobile number must be between 10 and 13 digits"
            : "";
        break;
      case "email":
        errors.email = !/\S+@\S+\.\S+/.test(value)
          ? "Invalid email address"
          : "";
        break;
      case "businessName":
        errors.businessName =
          value.length < 3 ? "Valid Business name is required" : "";
        break;
      default:
        break;
    }
    setFormErrors(errors);
    newFormData[name] = type === "checkbox" ? checked : value;
  }

  setFormData(newFormData);
};


  const isFormValid = () => {
    return Object.values(formErrors).every((error) => error === "");
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!isFormValid()) {
      Object.keys(formErrors).forEach((key) => {
        const error = formErrors[key];
        if (error.trim() !== "") {
          toast.error(`${error}`, {
            position: toast.POSITION.TOP_CENTER,
            className: "toast-message",
            delay: 500,
          });
        }
      });
      return;
    }
    setLoading(true);
    axios
      .post("https://api.fusioninnovative.com/leadEnquiry", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: false,
      })
      .then((response) => {
        if (response.data.status === false) {
          toast.error(response.data.data, {
            position: toast.POSITION.TOP_CENTER,
            className: "toast-message",
            delay: 500,
          });
        } else {
          console.log(response.data);
          toast.success("Form submitted successfully!", {
            position: toast.POSITION.TOP_CENTER,
            className: "toast-message",
            delay: 500,
          });
          setFormData({
          name: "",
          email: "",
          mobileNumber: "",
          businessType: "",
          businessName: "",
          businessAddress: "",
          website: "",
          url: "",
          trading: "",
          partners: [],
          service: "",
          update: "",
          receive: "",
          });
          
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  return (
    <section>
      <div className="form-main">
        <div className="small-color"></div>
        <Container>
          <Row>
            <Col>
              <div className="banner-image">
                <img src={Banner} alt="" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <form onSubmit={handleSubmit}>
                <div className="name">
                  <h3>Name</h3>

                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="eg: Jhon"
                  />
                </div>
                <div className="email">
                  <h3>Email</h3>

                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="eg: Jhon@gmail.com"
                  />
                </div>
                <div className="number">
                  <h3>Phone</h3>
                  <input
                    type="tel"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    placeholder="eg: 0123456789"
                  />
                </div>

                <div className="type">
                  <h3>Business Type</h3>
                  <div className="rest-take">
                    <div className="takeaway">
                      <input
                        type="radio"
                        name="businessType"
                        value="takeaway"
                        checked={formData.businessType === "takeaway"}
                        onChange={handleChange}
                      />
                      <div className="size"> Takeaway</div>
                    </div>
                    <div className="restaurant">
                      <input
                        type="radio"
                        name="businessType"
                        value="restaurant"
                        checked={formData.businessType === "restaurant"}
                        onChange={handleChange}
                      />
                      <div className="size"> Restaurant</div>
                    </div>
                  </div>
                </div>

                <div className="business-name">
                  <h3>Business Name</h3>
                  <input
                    type="text"
                    name="businessName"
                    value={formData.businessName}
                    onChange={handleChange}
                    placeholder="eg: Jhon Restaurant"
                  />
                </div>

                <div className="address">
                  <h3> Business Address</h3>
                  <textarea
                    name="businessAddress"
                    value={formData.businessAddress}
                    onChange={handleChange}
                    // placeholder="Business Address"
                  />
                </div>

                <div className="type">
                  <h3>Do you have online ordering website?</h3>
                  <div className="rest-take">
                    <div className="takeaway">
                      <input
                        type="radio"
                        name="website"
                        value="yes"
                        checked={formData.website === "yes"}
                        onChange={handleChange}
                      />
                      <div className="size"> yes</div>
                    </div>
                    <div className="restaurant">
                      <input
                        type="radio"
                        name="website"
                        value="No"
                        checked={formData.website === "No"}
                        onChange={handleChange}
                      />
                      <div className="size"> No</div>
                    </div>
                  </div>
                </div>
                {formData.website === "yes" && 
                
                <div className="url">
                  <h3> Website URL</h3>
                  <input
                    type="text"
                    name="url"
                    value={formData.url}
                    onChange={handleChange}
                    placeholder="www.domain.com"
                  />
                </div>
                  
                }
                <div className="trading">
                  <h3>How many years has the trading done ?</h3>
                  <input
                    type="text"
                    name="trading"
                    value={formData.trading}
                    onChange={handleChange}
                    placeholder="eg: 2 yrs"
                  />
                </div>
                  <div className="type">
                    <h3>Using any other 3rd party aggregator services? </h3>
                    <div className="rest-take">
                      {thirdparty.map(partner => (
                        <div className="restaurant" key={partner}>
                          <input
                            type="checkbox"
                            name="partners"
                            value={partner}
                            checked={formData.partners.includes(partner)}
                            onChange={handleChange}
                          />
                          <div className="size">{partner}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                <div className="type">
                  <h3> Do you need delivery service? </h3>
                  <div className="rest-take">
                    <div className="takeaway">
                      <input
                        type="radio"
                        name="service"
                        value="yes"
                        checked={formData.service === "yes"}
                        onChange={handleChange}
                      />
                      <div className="size"> yes</div>
                    </div>
                    <div className="restaurant">
                      <input
                        type="radio"
                        name="service"
                        value="No"
                        checked={formData.service === "No"}
                        onChange={handleChange}
                      />
                      <div className="size"> No</div>
                    </div>
                  </div>
                </div>
                <div className="conditions">
                  We would love to send you updates, exclusive offers and
                  invites to your email and/or SMS. Please note that we treat
                  your personal information with utmost care. We do not sell the
                  information acquired to others outside the company.*
                </div>
                <div className="check-one">
                  <input
                    type="checkbox"
                    name="update"
                    checked={formData.update}
                    onChange={handleChange}
                  />
                  <div className="size">
                    Yes, I would like to receive updates and exclusive invites
                    via email
                  </div>
                </div>
                <div className="check-one">
                  <input
                    type="checkbox"
                    name="receive"
                    checked={formData.receive}
                    onChange={handleChange}
                  />
                  <div className="size">
                    Yes, I would like to receive updates and exclusive invites
                    via sms
                  </div>
                </div>

                <div className="form-button">
                  {/* <button type="submit">Submit</button> */}
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? "Loading..." : "Submit"}
                  </Button>

                </div>
                <div className="size">
                  By using this site, you agree to <a  target="_blank" href="https://www.fusionkitchen.co.uk/general-terms">Fusion Kitchen terms </a> of use
                  and <a href="https://www.fusionkitchen.co.uk/privacy" target="_blank">privacy policy</a>.
                </div>
              </form>
            </Col>
          </Row>
        </Container>
        <div className="gradient"></div>
      </div>
    </section>
  );
}
