import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "./form/lead";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
       <ToastContainer autoClose={5000} transition={Flip} />      
      <Form />
    </>
  );
}

export default App;
